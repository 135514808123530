$width-4x6: 4in;
$height-4x6: 6in;

.fungi-typeahead {
  text-transform: capitalize;   
}

// Hide everything but labels to print
footer,
aside,
nav,
form,
iframe,
.no-print {
  @media print {
    visibility: hidden !important;
    display: none;
  }
}

html,
body,
#root,
.app .container {
  @media print {
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
  }
}

// 4x6 label
.label {
  visibility: visible !important;
  margin: 0;
  padding: 0;

  font-family: "Helvetica", "Arial", sans-serif;

  &.label-4x6 {
    width: $width-4x6;
    height: $height-4x6;

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: column;

    @media print {
      position: absolute;
      top: 0;
      left: 0;
      
      a {
        color: black;
        text-decoration: none;
      }
    }

    @media screen {
      border: 1px solid black;
      margin-top: 10px;
      // display: none; /* Hide label on screen */
    }

    &.preview {
      @media screen {
        display: none;
      }
    }
  }

  &.label-hidden {
    display: none;
  }

  &.label-header {
    width: $width-4x6;
    min-width: $width-4x6;
    padding-left: 2px;
    padding-right: 2px;

    .label-header-fungi {
      font-size: 2.5rem;
      text-transform: capitalize; 
    }
    .label-header-latin {
      font-size: 1.2em;
      text-transform: capitalize; 
    }
    .label-header-inoculation {
      padding-top: 2px;
    }
    .label-header-substrate {
      padding-top: 2px;
    }
  }
  
  &.label-barcode {
    width: $width-4x6;
    min-width: $width-4x6;
    text-align: center;
    border-top: 1px solid black;
    padding-top: 1px;
  }
  
  &.label-qrcode {
    width: $width-4x6;
    min-width: $width-4x6;

    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;

    border-top: 1px solid black;
    

    .label-qrcode-code {
      text-align: center;
      padding: 5px;
      width: 1in+10px;
      position: relative;
      svg {
        width: 100%;
        height: 100%;
        margin: auto;
        position: relative;
      }
    }

    .label-qrcode-code-full {
      flex-grow: 2;
      padding: 5px;
    }
    
    .label-qrcode-text {
      padding: 5px;
      width: 3in-10px;
      font-family: "Courier New", Courier, monospace;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  &.label-notes {
    width: $width-4x6;
    min-width: $width-4x6;
    flex-grow: 2;
    padding: 2px;

    border-top: 1px solid black;

    text-align: center;   
    
  }
}
