.signup.signup-wrapper {
  width: 300px;
}

.signup.signup-form {
  width: 250px;
}

.signup.signup-form input {
  margin-top: 5px;
}
